import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider, Text,
    Button, Card, Input, Link, Small
} from "../layout/Content";
import React, {useEffect, useState} from "react";
import {Footer} from "../part/Footer";
import {Header} from "../part/Header";
import {useNavigate} from "react-router-dom";
import {Announcement, Empty, InlineImage, ScrollableContainer, ScrollableElement} from "../layout/Defaults";
import LikeButton from "../part/LikeButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faComment} from "@fortawesome/free-regular-svg-icons";
import {faBookmark, faEnvelopeOpenText, faThumbtack} from "@fortawesome/free-solid-svg-icons";
import {calculateReadingTime, formatReadingTime} from "../../lib/Time";
import {UserProfile, useUserProfileContext} from "../../lib/UserContext";
import {getAvatarURLForUser} from "../../lib/Icon";

interface Post {
    uuid: string;
    title: string;
    content: string;
    total_likes?: number;
    comments?: {
        total_comments: number;
    }
    owner?:any;
    // Add other post properties as needed
}


export function Feed() {
    const navigate = useNavigate();
    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();

    const [loading, setLoading] = useState<boolean>(false);
    const [announce, setAnnounce] = useState<boolean>(true);

    const [posts, setPosts] = useState<Post[]>([]);
    const [users, setUsers] = useState<UserProfile[]>([]);


    const fetchRecommendedPosts = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/recommend/post`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors', // This needs to be 'cors' to follow CORS rules
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setPosts(data.results);
            } else {
                console.error('Failed to fetch recommended posts');
            }
        } catch (error) {
            console.error('Error fetching recommended posts:', error);
        } finally {
            // setLoading(false);
        }
    };
    const fetchRecommendedUsers = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_DOMAIN_API}/recommend/user`, {
                method: 'GET',
                credentials: 'include',
                mode: 'cors', // This needs to be 'cors' to follow CORS rules
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setUsers(data.results);
            } else {
                console.error('Failed to fetch recommended users');
            }
        } catch (error) {
            console.error('Error fetching recommended users:', error);
        } finally {
            // setLoading(false);
        }
    };

    const ready = () => {
        return true;
    }

    useEffect(() => {
        // if (userProfile) {
        fetchRecommendedPosts();
        fetchRecommendedUsers();
        //  }
    }, [getUserProfile()]);

    return (
        <Layout className="landing-page">
            <Header/>
            <Page Grow>
                <Content Pad style={{height: "100%"}}>
                    <Row Fill>
                        <Col xs={24} className={"Flex"}>
                            <div className={"limits wide padH"}>
                                <Space direction={"vertical"} Gap>
                                    <Row Gap>

                                        {(!getUserProfile() && hasQueriedUserProfile() && announce)&&<Col xs={24}>
                                            <Announcement onClose={()=>{setAnnounce(false)}}><span style={{display:"inline-block"}}>This feed is limited to <strong>Safe-for-work</strong>, <strong>Standard</strong> Quality, and <strong>Free</strong> Content only.</span> <span  style={{display:"inline-block"}}> <Link
                                                href={"/signin"}>Sign in</Link> to unlock everything.</span></Announcement>
                                        </Col>}
                                        <Col xs={24}>
                                            <Space Gap>
                                                <div style={{width: "100%"}}>


                                                    <ScrollableContainer>
                                                        {users.length > 0 ? (
                                                            users.map((user: UserProfile, index) => (
                                                                <ScrollableElement key={index}
                                                                                   imageSrc={getAvatarURLForUser(user)}
                                                                                   altText={user.attributes.displayName}
                                                                                   onClick={() => {
                                                                                       navigate("/u/" + user.uuid);
                                                                                   }}/>
                                                            ))
                                                        ) : (
                                                            <p>No users available.</p>
                                                        )}
                                                    </ScrollableContainer>

                                                </div>

                                            </Space>
                                        </Col>

                                            {posts.length > 0 ? (
                                                posts.map((post:Post) => (
                                                    <Col xs={24} md={12} lg={8}>
                                                        <Card Pad Wide Full>
                                                            <Space GapSm direction={"vertical"} Full>
                                                                <Space direction={"vertical"}>
                                                                    <span><InlineImage src={getAvatarURLForUser(post.owner)}/> {post.owner&&<Link
                                                                        href={`/u/${post.owner.uuid}`}><Small>{(post.owner.attributes.displayName)}</Small></Link>} {post.owner&&<Small Muted>@{(post.owner.attributes.username)}</Small>}</span>
                                                                    <h1>{post.title}</h1></Space>

                                                                <Space justify={"space-between"}><Small>Liked by<strong> {post.total_likes||0} other{post.total_likes===1?"":"s"}</strong></Small><Small><FontAwesomeIcon fixedWidth icon={faClock} /> {formatReadingTime(calculateReadingTime(post.content))}</Small></Space>
                                                                <Space justify={"space-between"} GapSm>
                                                                    <LikeButton uuid={post.uuid}/>
                                                                    {post.comments&&<Button type={"link"} icon={<FontAwesomeIcon icon={faComment}/>}>{post.comments.total_comments}</Button>}
                                                                    <div className={"Grow"}></div>
                                                                    <Button type={"link"} icon={<FontAwesomeIcon icon={faEnvelopeOpenText}/>} href={"/p/"+post.uuid}><span className={"hide-sm"}>Read</span></Button>
                                                                    <Button type={"link"} icon={<FontAwesomeIcon icon={faThumbtack}/>} href={"/p/"+post.uuid}><span className={"hide-sm"}>Save</span></Button>
                                                                </Space>

                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                ))
                                            ) : (
                                                <p>No posts available.</p>
                                            )}


                                    </Row>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Page>
            <Footer/>
        </Layout>
    );
}